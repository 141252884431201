import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from 'utils/api';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  academicLevels: []
};

const slice = createSlice({
  name: 'academicLevels',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setAcademicLevels(state, action) {
      state.academicLevels = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getAcademicLeveles(countryCode) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/academic-levels/country/${countryCode}`);
      dispatch(slice.actions.setAcademicLevels(response.data));
      // return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
