import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
// hooks
import useCountry from '../../hooks/useCountry';
import useAuth from '../../hooks/useAuth';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

export default function LanguagePopover() {
  const location = useLocation();
  const anchorRef = useRef(null);
  const { role } = useAuth();
  const [open, setOpen] = useState(false);
  const { allCountries, countryCodes, onChangeCountry } = useCountry();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCountry = (value) => {
    onChangeCountry(value);
    handleClose();
  };

  return (
    countryCodes &&
    location.pathname === '/dashboard/interactivasc' &&
    role === 'isAdmin' && (
      <>
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img src={countryCodes.icon} alt={countryCodes.label} width={26} height={`'auto'`} />
        </MIconButton>

        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
          {allCountries.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === countryCodes.value}
              onClick={() => handleChangeCountry(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} width={26} height={`'auto'`} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuPopover>
      </>
    )
  );
}
