/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Stack,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  ListSubheader,
  ListItemButton
} from '@mui/material';
// hooks
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LyricsIcon from '@mui/icons-material/Lyrics';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Logo from '../../components/Logo';
import logo from '../../assets/asc-v2.png';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name, size) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={size ? { width: size, height: size } : { width: '100%', height: '100%' }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  coach: getIcon('ic_coach'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_home'),
  activity: getIcon('ic_acitvity'),
  booking: getIcon('ic_booking'),
  school: getIcon('ic_school'),
  teacher: getIcon('ic_teacher'),
  circle: getIcon('circle-icon', '5px'),
  directPayment: getIcon('ic_directPayment')
};
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);
const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));
// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user, role, isColombiaUser, isColombiaSierraUser, isKrismar, isImex, isPreschoolFFE, isPreschool } =
    useAuth();
  const { avatar } = user;
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);
  const sidebarConfig = [
    {
      id: 1,
      items: [
        {
          title: 'Inicio',
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
          show: true
        }
      ]
    },
    {
      id: 2,
      subheader: 'Formación',
      showHeader: Boolean(
        role === 'isTeacher' ||
          role === 'isAdmin' ||
          role === 'isASC' ||
          role === 'isDirective' ||
          role === 'isAdministrative' ||
          isKrismar
      ),
      items: [
        {
          title: 'Cursos de inducción',
          path: PATH_DASHBOARD.general.training,
          icon: ICONS.circle,
          show: Boolean(
            role === 'isAdmin' ||
              role === 'isASC' ||
              role === 'isTeacher' ||
              role === 'isDirective' ||
              role === 'isAdministrative'
          )
        },
        {
          title: 'Formación tecnológica',
          // path: PATH_DASHBOARD.preschool.dialogs,
          icon: <LibraryBooksIcon />,
          show: Boolean(
            role === 'isAdmin' ||
              role === 'isASC' ||
              role === 'isTeacher' ||
              role === 'isDirective' ||
              role === 'isAdministrative'
          ),
          children: [
            {
              title: 'Biblioteca de consulta',
              path: PATH_DASHBOARD.trainingTec,
              show: Boolean(
                role === 'isAdmin' ||
                  role === 'isASC' ||
                  role === 'isTeacher' ||
                  role === 'isDirective' ||
                  role === 'isAdministrative'
              )
            },
            {
              title: 'Acercamiento Tecnológico',
              path: PATH_DASHBOARD.scheduletec,
              show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
            }
          ]
        }
        // {
        //   title: 'Formación tecnológica',
        //   path: PATH_DASHBOARD.trainingTec,
        //   icon: ICONS.circle,
        //   show: Boolean(role === 'isAdmin' || role === 'isASC' || role === 'isTeacher')
        // }
        // { title: 'Agenda', path: PATH_DASHBOARD.scheduletec, icon: ICONS.calendar, show: Boolean(role === 'isAdmin') },
      ]
    },
    {
      id: 2,
      subheader: 'Contenido educativo',
      showHeader: Boolean(role === 'isAdmin' || (isPreschoolFFE && isPreschool)),
      items: [
        // {
        //   title: 'Actividades interactivASC',
        //   path: PATH_DASHBOARD.interactivas.root,
        //   icon: ICONS.circle,
        //   show: Boolean(
        //     isKrismar && (role === 'isAdmin' || role === 'isASC' || role === 'isTeacher' || role === 'isStudent')
        //   )
        // },
        // {
        //   title: role === 'isAdmin'  ? 'Libros LASIS' : 'Libros',
        //   path: PATH_DASHBOARD.bookSierra.root,
        //   icon: ICONS.circle,
        //   show: Boolean(isColombiaSierraUser || role === 'isAdmin')
        // },
        // {
        //   title: role === 'isAdmin' ? 'Libros colombia' : 'Libros ',
        //   path: PATH_DASHBOARD.bookcolombia.root,
        //   icon: ICONS.circle,
        //   show: Boolean(isColombiaUser || role === 'isAdmin')
        // },
        // {
        //   title: role === 'isAdmin' ? 'Recursos colombia' : 'Recursos complementarios',
        //   path: PATH_DASHBOARD.recursoscolombia.root,
        //   icon: ICONS.circle,
        //   show: Boolean(isColombiaUser || role === 'isAdmin')
        // },
        {
          title: 'Preescolar FFE',
          // path: PATH_DASHBOARD.preschool.dialogs,
          icon: <LibraryBooksIcon />,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative' || isPreschoolFFE),
          children: [
            { title: 'Dialogos', path: PATH_DASHBOARD.preschool.dialogs, show: Boolean(role === 'isAdmin') },
            { title: 'Canciones', path: PATH_DASHBOARD.preschool.songs, show: Boolean(role === 'isAdmin') },
            { title: 'Audios', path: PATH_DASHBOARD.preschool.audios, show: Boolean(role === 'isAdmin') },
            { title: 'Mind maps', path: PATH_DASHBOARD.preschool.mindMaps, show: Boolean(role === 'isAdmin') }
          ]
        }
      ]
    },
    {
      id: 3,
      subheader: 'Herramientas',
      showHeader: Boolean(role === 'isAdmin'),
      items: [
        {
          title: 'Biblioteca',
          path: PATH_DASHBOARD.captec,
          icon: <LibraryBooksIcon />,
          show: Boolean(role === 'isAdmin'),
          children: [
            { title: 'Lecturas recomendadas', path: PATH_DASHBOARD.library },
            {
              title: 'Fundamentos pedagógicos',
              path: PATH_DASHBOARD.libraryTeachers,
              show: Boolean(isImex || role === 'isAdmin')
            }
          ]
        },
        { title: 'Agenda', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar, show: Boolean(role === 'isAdmin') },
        {
          title: 'Acercamiento Pedagógico ASC',
          path: PATH_DASHBOARD.coach,
          icon: ICONS.coach,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
        }
        // { title: 'Ticket', path: PATH_DASHBOARD.mail.root, icon: ICONS.chat, show: Boolean(role === 'isAdmin') }
      ]
    },
    // {
    //   id: 4,
    //   subheader: 'Prescolar',
    //   showHeader: Boolean(role === 'isAdmin' || isPreschool),
    //   items: [
    //     {
    //       title: 'Dialogos',
    //       path: PATH_DASHBOARD.preschool.dialogs,
    //       icon: <RecordVoiceOverIcon />,
    //       show: Boolean(role === 'isAdmin' || isPreschool)
    //     },
    //     {
    //       title: 'Canciones',
    //       path: PATH_DASHBOARD.preschool.songs,
    //       icon: <LyricsIcon />,
    //       show: Boolean(role === 'isAdmin' || isPreschool)
    //     },
    //     {
    //       title: 'Audios',
    //       path: PATH_DASHBOARD.preschool.audios,
    //       icon: <LibraryMusicIcon />,
    //       show: Boolean(role === 'isAdmin' || isPreschool)
    //     },
    //     {
    //       title: 'Mind Maps',
    //       path: PATH_DASHBOARD.preschool.mindMaps,
    //       icon: <PsychologyAltIcon />,
    //       show: Boolean(role === 'isAdmin' || isPreschool)
    //     }
    //   ]
    // },
    // {
    //   id: 5,
    //   subheader: 'Tecnología',
    //   showHeader: Boolean(role === 'isAdmin'),
    //   items: [
    //     // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     {
    //       title: 'Capacitación tecnológica ',
    //       path: PATH_DASHBOARD.trainingTec,
    //       icon: <ModelTrainingIcon />,
    //       show: Boolean(role === 'isAdmin'),
    //       children: [
    //         { title: 'Bibliteca de consulta', path: PATH_DASHBOARD.trainingTec },
    //         { title: 'Agendar capacitación', path: PATH_DASHBOARD.scheduletec }
    //       ]
    //     },
    //     {
    //       title: 'App de contenidos',
    //       path: PATH_DASHBOARD.docapp,
    //       icon: <AppSettingsAltIcon />,
    //       show: Boolean(role === 'isAdmin')
    //     }
    //   ]
    // }
    {
      id: 6,
      subheader: 'Directivos',
      showHeader: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative'),
      items: [
        {
          title: 'Colegio',
          path: PATH_DASHBOARD.manager.root,
          icon: ICONS.calendar,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
        },
        {
          title: 'Alumnos',
          path: PATH_DASHBOARD.manager.students,
          icon: ICONS.calendar,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
        },
        {
          title: 'Profesores',
          path: PATH_DASHBOARD.manager.teachers,
          icon: ICONS.calendar,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
        },
        {
          title: 'Logistica',
          path: PATH_DASHBOARD.manager.logistics,
          icon: ICONS.calendar,
          show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative')
        }
        // {
        //   title: 'Pagos TC Colegio',
        //   path: PATH_DASHBOARD.manager.directPayment,
        //   icons: ICONS.directPayment,
        //   show: Boolean(role === 'isAdmin' || role === 'isDirective' || role === 'isAdministrative') // Actualizado para incluir isDirective
        // }
        // {
        //   title: 'Pago colegio',
        //   path: PATH_DASHBOARD.manager.paymentSchool,
        //   icon: ICONS.calendar,
        //   show: Boolean(role === 'isAdmin' || role === 'isDirective') // Actualizado para incluir isDirective (si decides descomentar esta sección)
        // }
      ]
    }
  ];

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to={PATH_DASHBOARD.general.app} sx={{ display: 'inline-flex' }}>
            <img src={logo} width="100px" alt="logo not found" />
          </Box>

          {/* <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden> */}
        </Stack>

        {isCollapse ? (
          <MyAvatar src={`/static/mob-avatar/${avatar}-01.png`} sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
            <AccountStyle>
              <MyAvatar src={`/static/mob-avatar/${avatar}-01.png`} />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                  {user && user.person.firstName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user && role === 'isASC' && 'ASC'}
                  {user && role === 'isAdmin' && 'Super Admin'}
                  {user && role === 'isTeacher' && 'Maestro'}
                  {user && role === 'isStudent' && 'Alumno'}
                  {user && role === 'isDirective' && 'Directivo'}
                  {user && role === 'isAdministrative' && 'Administrativo'}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      <ListSubheaderStyle>Enlaces externos</ListSubheaderStyle>
      {user &&
        (role === 'isAdmin' ||
          role === 'isASC' ||
          role === 'isTeacher' ||
          role === 'isDirective' ||
          role === 'isAdministrative') && (
          <>
            <ListItemStyle href="https://app.asc.education/" target="_blank">
              Aplicación ASC
            </ListItemStyle>
            <ListItemStyle href="https://hga.asc.education/" target="_blank">
              HGA
            </ListItemStyle>
            <ListItemStyle href="https://blog.asc.education/" target="_blank">
              Blog
            </ListItemStyle>
          </>
        )}
      <Box sx={{ color: '#fff', fontSize: '1px', marginTop: '1rem' }}>kmlk</Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)',
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
