import { useState, useEffect } from 'react';
// redux
import { useDispatch } from '../redux/store';
import { setCountryActive, getAcademicLeveles } from '../redux/slices/user';
// material
// import { enUS, deDE, frFR } from '@mui/material/locale';

// ----------------------------------------------------------------------

const COUNTRIES = [
  {
    label: 'México',
    value: 'MX',
    // systemValue: enUS,
    icon: '/static/icons/ic_flag_mx.svg'
  },
  {
    label: 'Colombia',
    value: 'CO',
    // systemValue: deDE,
    icon: '/static/icons/ic_flag_co.svg'
  },
  {
    label: 'Panama',
    value: 'PA',
    // systemValue: frFR,
    icon: '/static/icons/ic_flag_pa.svg'
  }
];

export default function useCountry() {
  const dispatch = useDispatch();
  const [countryCodes, setCountry] = useState('MX');
  // const { i18n, t: translate } = useTranslation();
  // const countryStorage = localStorage.getItem('i18nextLng');
  // if (countryStorage) handleChangeCountry(countryStorage);
  // const currentLang = LANGS.find((_lang) => _lang.value === countryStorage) || LANGS[1];

  const handleChangeCountry = (newCountryCode) => {
    setCountry(COUNTRIES.find((_country) => _country.value === newCountryCode));
    dispatch(setCountryActive(newCountryCode));
    dispatch(getAcademicLeveles(newCountryCode));
  };
  useEffect(() => {
    setCountry(COUNTRIES.find((_country) => _country.value === 'MX'));
  }, [dispatch]);
  return {
    countryCodes,
    onChangeCountry: handleChangeCountry,
    // currentLang,
    allCountries: COUNTRIES
  };
}
