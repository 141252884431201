import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
// utils
import axiosInstance from '../../utils/api';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  books: [],
  deviceId: null
};

const slice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET Device Id
    setDeviceId(state, action) {
      state.isLoading = false;
      state.deviceId = action.payload;
    },

    // GET Device Id
    setBookList(state, action) {
      state.isLoading = false;
      state.books = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setDeviceId } = slice.actions;

// ----------------------------------------------------------------------

export function getDeviceId(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const body = {
      deviceIdentifier: uuidv4(),
      deviceInfo: {
        os: 'Windows',
        version: 'NT'
      }
    };
    try {
      const response = await axiosInstance.post(`/devices/${user.id}`, body);
      dispatch(slice.actions.setDeviceId(response.data.id));
      localStorage.setItem('deviceId', response.data.id);
      return response.data.id;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookList(deviceId, user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`/firebasebooks/digitalContents/${user.id}/${deviceId}`);
      dispatch(slice.actions.setBookList(JSON.stringify(response.data)));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
