import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slice
import userReducer from './slices/user';
import academicLevelsReducer from './slices/academicLevels';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import interactivasReducer from './slices/interactivas';
import bookReducer from './slices/book';
import managerReducer from './slices/mangerSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  manager: managerReducer,
  academicLevels: academicLevelsReducer,
  user: userReducer,
  calendar: calendarReducer,
  interactivas: interactivasReducer,
  book: bookReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
