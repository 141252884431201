import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axiosInstance from 'utils/api';

// Estado inicial
const initialState = {
  isLoading: false,
  error: false,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null
};

// Crear slice
const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = filter(state.events, (event) => event.id !== eventId);
      state.isLoading = false;
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, selectRange } = slice.actions;

// Obtener eventos con ID de usuario dinámico
export function getEvents(date, view, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let startOf;
    let endOf;

    if (view === 'timeGridWeek') {
      startOf = moment(date).startOf('week').toISOString();
      endOf = moment(date).endOf('week').toISOString();
    } else if (view === 'dayGridMonth') {
      startOf = moment(date).startOf('month').toISOString();
      endOf = moment(date).endOf('month').toISOString();
    } else {
      startOf = moment(date).startOf('day').toISOString();
      endOf = moment(date).endOf('day').toISOString();
    }

    try {
      const response = await axiosInstance.get('/calendars', {
        params: {
          userId,
          start: startOf,
          end: endOf
        }
      });

      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Crear evento
export function createEvent(newEvent, user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        title: newEvent.title,
        userId: user.id,
        start: moment(newEvent.start).toISOString(),
        end: moment(newEvent.end).toISOString()
      };
      const response = await axiosInstance.post('/calendar', data);
      dispatch(slice.actions.createEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Actualizar evento
export function updateEvent(eventId, updateEvent, user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.put(`/calendar/${eventId}`, {
        title: updateEvent.title,
        userId: user.id,
        start: updateEvent.start,
        end: updateEvent.end
      });
      dispatch(slice.actions.updateEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Eliminar evento
export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosInstance.delete(`/calendar/${eventId}`);
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
