import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};
export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 385.175 262.176">
        <g id="logo_asc_horizontal_simple_RGB" transform="translate(-9 7)">
          <g id="Login" transform="translate(9 -7)">
            <g id="Group-4" transform="translate(0)">
              <g id="logo_asc_vertical_oficial_RGB">
                <g id="Group">
                  <g id="Group-3">
                    <path
                      fill={PRIMARY_MAIN}
                      id="Shape"
                      className="cls-1"
                      d="M322.9,17.534c-6.338,0-84.179.921-129.561,42.073a1.12,1.12,0,0,1-1.5,0C146.461,18.455,68.615,17.538,62.278,17.534a1.11,1.11,0,0,0-.729.271L.384,70.165a1.082,1.082,0,0,0-.367,1.012L1.205,78a1.106,1.106,0,0,0,1,.9C155.3,91.248,184.089,155.764,186.923,163.246a1.112,1.112,0,0,0,1.04.71h9.249a1.11,1.11,0,0,0,1.041-.71c2.839-7.481,31.63-72,184.714-84.344a1.105,1.105,0,0,0,1-.9l1.188-6.822a1.1,1.1,0,0,0-.362-1.012l-61.169-52.36a1.113,1.113,0,0,0-.729-.271"
                      transform="translate(0 98.22)"
                    />
                    <path
                      fill={PRIMARY_MAIN}
                      id="Shape-2"
                      data-name="Shape"
                      className="cls-1"
                      d="M177.5,8.553c-3.194,0-42.4.464-65.255,21.234a.57.57,0,0,1-.756,0C88.636,9.017,49.433,8.553,46.237,8.553a.593.593,0,0,0-.364.131L15.067,35.118a.547.547,0,0,0-.184.515l.6,3.44a.555.555,0,0,0,.506.46c77.1,6.23,91.6,38.8,93.03,42.57a.564.564,0,0,0,.521.36H114.2a.565.565,0,0,0,.521-.36c1.428-3.773,15.928-36.339,93.03-42.57a.553.553,0,0,0,.506-.46l.6-3.44a.559.559,0,0,0-.184-.515L177.868,8.686a.583.583,0,0,0-.364-.133"
                      transform="translate(82.123 47.646)"
                    />
                    <path
                      fill={PRIMARY_MAIN}
                      id="Shape-3"
                      data-name="Shape"
                      className="cls-1"
                      d="M99.978,3.849c-1.525,0-20.278.219-31.208,10.015a.27.27,0,0,1-.364,0C57.476,4.07,38.728,3.849,37.2,3.849a.275.275,0,0,0-.175.063L22.293,16.381a.259.259,0,0,0-.088.238l.286,1.624a.275.275,0,0,0,.245.219c36.871,2.938,43.8,18.3,44.492,20.074a.262.262,0,0,0,.249.175h2.23a.274.274,0,0,0,.249-.175c.681-1.777,7.621-17.134,44.492-20.074a.278.278,0,0,0,.245-.219l.286-1.624a.259.259,0,0,0-.088-.238L100.155,3.912a.294.294,0,0,0-.177-.063"
                      transform="translate(122.59 21.252)"
                    />
                    <path
                      fill={PRIMARY_MAIN}
                      id="Shape-4"
                      data-name="Shape"
                      className="cls-1"
                      d="M61.439,1.924c-.7,0-9.218.1-14.187,4.807a.123.123,0,0,1-.163,0C42.119,2.027,33.6,1.924,32.9,1.924a.191.191,0,0,0-.077.027l-6.7,5.987a.119.119,0,0,0-.04.116l.13.78a.121.121,0,0,0,.108.1c16.762,1.407,19.915,8.784,20.226,9.638a.12.12,0,0,0,.113.084h1.013a.129.129,0,0,0,.117-.084c.307-.854,3.46-8.231,20.226-9.638a.112.112,0,0,0,.1-.1l.13-.78a.112.112,0,0,0-.04-.116l-6.7-5.987a.1.1,0,0,0-.074-.027"
                      transform="translate(144.013 10.626)"
                    />
                    <path
                      fill={PRIMARY_MAIN}
                      id="Shape-5"
                      data-name="Shape"
                      className="cls-1"
                      d="M44.876,8,42.2,5.463a.079.079,0,0,0-.018-.015h-.018L36.447,0,28.04,8a.057.057,0,0,0-.018.045l.056.335a.05.05,0,0,0,.046.04c6.7.6,7.964,3.732,8.089,4.093a.074.074,0,0,0,.023.035h.423s.041-.015.047-.035c.125-.361,1.387-3.494,8.089-4.093a.05.05,0,0,0,.041-.04l.052-.335A.041.041,0,0,0,44.876,8"
                      transform="translate(154.725)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}
