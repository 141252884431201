import { useLocation, Outlet } from 'react-router-dom';

// components
import MainNavbar from './MainNavbar';
import Footer from './Footer';

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  return (
    <>
      {!isHome ? <></> : <MainNavbar />}
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <></>
      ) : (
        <>
          <Footer />
        </>
      )}
    </>
  );
}
