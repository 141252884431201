// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
// import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RtlLayout>
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </RtlLayout>
        </LocalizationProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
