/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
import axiosInstance from './api';

export const decodeToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const getHumanDateAndTime = (date) => {
  if (date == null || date === '') return date;
  const arrayDate = date.split(/[^0-9]/);
  if (typeof date === 'string' || typeof date === 'number') date = new Date(date.replace(/\s/, 'T'));
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${day}/${month}/${date.getFullYear()} a las ${arrayDate[3]}:${minutes}:${seconds}`;
};

export const getPersonFullName = (person) => {
  let fullName = person.firstName ? ` ${person.firstName}` : '';
  fullName += person.lastName ? ` ${person.lastName}` : '';
  fullName += person.secondLastName ? ` ${person.secondLastName}` : '';
  return fullName;
};

export const getDateYear = (date) => {
  if (date === null || date === '') return date;
  if (typeof date === 'string' || typeof date === 'number') date = new Date(date.replace(/\s/, 'T'));
  return `${date.getFullYear()}`;
};

export const formatCurrency = (amount = 0, currencyCode = '') => {
  amount = amount.toString().replace(/\$|\,/g, '');
  if (currencyCode === undefined) currencyCode = '';
  else currencyCode = ` ${currencyCode}`;
  if (isNaN(amount)) amount = '0';
  // determinar su necesitamos signo
  let sign = '';
  if (amount < 0) sign = '-';
  // encontrar los centavos
  amount = Math.floor(amount * 100 + 0.501);
  let cents = amount % 100;
  amount = Math.floor(amount / 100).toString();
  if (cents < 10) cents = `0${cents}`;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
    amount = `${amount.substring(0, amount.length - (4 * i + 3))},${amount.substring(amount.length - (4 * i + 3))}`;
  }
  // devolver el numero con formato
  return `${sign}$${amount}.${cents}${currencyCode}`;
};

const compareRoles = (allowedRoles, userRoles) => {
  let match = false;
  allowedRoles.forEach((allowedRole) => {
    userRoles.forEach((userRole) => {
      if (userRole === allowedRole) match = true;
    });
  });

  return match;
};

export const checkPermissions = (allowedRoles, currentUser) => {
  const userRoles = currentUser.data.roles.map((role) => role.roleCode);
  const userFamilies = currentUser.data.roles.map((role) => role.role.familyCode);
  const roles = userRoles.concat(userFamilies);
  if (compareRoles(allowedRoles, roles)) return true;
  return false;
};

export const onAuthentication = async (response) => {
  const token = response.data.jwt;
  const authenticated = true;
  // const decodedToken = decodeToken(token);
  localStorage.setItem('asc-admin-jwt', token);
  if (authenticated) return true;
  return false;
};

export const logOut = () => {
  localStorage.removeItem('accessToken');
  delete axiosInstance.defaults.headers.common.Authorization;
  return false;
};

export const checkAuth = async (token) => {
  try {
    const response = await axiosInstance.post('users/reauthenticate', { jwt: token });
    const auth = await onAuthentication(response);
    return auth;
  } catch (error) {
    logOut();
    throw error;
  }
};

export const validateEmail = (email) => {
  if (email !== '' && email !== null && email !== undefined) {
    const match = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (match === null) return true;
    return false;
  }
  return true;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
